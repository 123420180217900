<template>
  <div class="container">
    <Header></Header>
      <div v-html="model.content"></div>
    <Footer></Footer>
     <el-backtop style="width:50px;height:50px;">
      <div
        style="{
          height: 100%;
          width: 100%;
          background: #013656 ;
          box-shadow: 0 0 6px rgba(0,0,0, .12);
          text-align: center;
          z-index: 9999;
        }"
      >
        <img src="https://api.starmaxintl.com/storage/file/20220715/e75486d409260e76f43ab4aafd176fd88b17e6a8.png" alt="" style="vertical-align: top;">
      </div>
    </el-backtop>
  </div>  
</template>

<script>
import Header from "@/components/header";
import Footer from "@/components/footer";
import { info } from '@/api/about'

export default {
  components: {
    Header,
    Footer,
  },
  data() {
    return {
	  model: {}
    };
  },
  created() {
    this.info()
  },
  methods: {
    info(){
	  info().then(res => {
        this.model=res.data.data
      }).catch(() => {
      })
	},
    clickLink () {
      this.$router.push({path:'/home'});
    },

    handleChange(val) {
      console.log(val);
    }
  },
  
  mounted(){
    new this.$wow.WOW().init({
      boxClass: 'wow',
      animateClass: 'animate__animated',
      offset: 0,
      mobile: true,
      live: false
    });
 },
  watch: {
     'model.content'(newValue, oldValue) {
       //console.log(newValue);
	   this.$nextTick(() => {
        new this.$wow.WOW().init({
		  boxClass: 'wow',
		  animateClass: 'animate__animated',
		  offset: 0,
		  mobile: true,
		  live: false	
		});
      });
     }
  },
}
</script>